import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BellIcon, BellRingIcon, Trash2, Plus } from "lucide-react";
import ReminderModal from "../components/ReminderModal";
import { Toast } from "../components/Toast";

interface Column {
  key: string;
  label: string;
}

interface CustomRender {
  [key: string]: (row: any) => React.ReactNode;
}

interface DynamicTableProps {
  data: any[];
  columns: Column[];
  startIndex: number;
  selectedRows: number[];
  setSelectedRows: React.Dispatch<React.SetStateAction<number[]>>;
  onSort: (key: string) => void;
  sortColumn: string | null;
  sortDirection: "asc" | "desc" | null;
  onSetReminder: any;
  onDelete: (id: number) => void;
  customRender?: CustomRender;
  isMasterList?: boolean;
  onAddToMyList?: (algorithm: any) => void;
  onRowClick?: (row: any) => void; // Add this line
}

const DynamicTable: React.FC<DynamicTableProps> = ({
  data,
  columns,
  startIndex,
  selectedRows,
  setSelectedRows,
  onSort,
  sortColumn,
  sortDirection,
  onSetReminder,
  onDelete,
  customRender,
  isMasterList = false,
  onAddToMyList,
}) => {
  const [reminderModalOpen, setReminderModalOpen] = useState(false);
  const [selectedAlgorithm, setSelectedAlgorithm] = useState<any>(null);
  const [algorithmToDelete, setAlgorithmToDelete] = useState<number | null>(
    null,
  );
  const [showToast, setShowToast] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const getSortIcon = (columnKey: string) => {
    if (sortColumn === columnKey) {
      return sortDirection === "asc" ? "▲" : "▼";
    }
    return null;
  };

  const renderCell = (row: any, column: Column) => {
    switch (column.key) {
      case "title":
        if (isMasterList) {
          return <span className="font-medium">{row[column.key]}</span>;
        } else {
          return (
            <div className="flex items-center">
              <span className="mr-2">{row[column.key]}</span>
              <button
                onClick={(e) => handleReminderClick(e, row)}
                className="btn btn-ghost btn-xs"
              >
                {row.reminder ? (
                  <BellRingIcon className="h-4 w-4 text-primary" />
                ) : (
                  <BellIcon className="h-4 w-4" />
                )}
              </button>
            </div>
          );
        }
      case "solved":
        return (
          <span
            className={`badge ${row[column.key] ? "badge-success" : "badge-error"}`}
          >
            {row[column.key] ? "Solved" : "Unsolved"}
          </span>
        );
      case "level":
        return (
          <span className={`badge ${getLevelBadgeColor(row[column.key])}`}>
            {row[column.key]}
          </span>
        );
      case "actions":
        if (isMasterList) {
          return (
            <button
              onClick={(e) => {
                e.stopPropagation();
                onAddToMyList && onAddToMyList(row);
              }}
              className="btn btn-primary btn-sm"
            >
              <Plus className="h-4 w-4 mr-1" /> Add to My List
            </button>
          );
        }
        return null;
      default:
        return row[column.key];
    }
  };

  const handleReminderClick = (e: React.MouseEvent, row: any) => {
    e.stopPropagation();
    setSelectedAlgorithm(row);
    setReminderModalOpen(true);
  };

  const isToday = (dateString: string | null | undefined) => {
    if (!dateString) return false;
    const today = new Date();
    const reminderDate = new Date(dateString);
    return today.toDateString() === reminderDate.toDateString();
  };

  const dueToday = data.filter((row) => isToday(row.reminder));
  const otherProblems = data.filter((row) => !isToday(row.reminder));

  const handleDeleteClick = (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    setAlgorithmToDelete(id);
    setShowDeleteConfirm(true);
  };

  const confirmDelete = () => {
    if (algorithmToDelete) {
      onDelete(algorithmToDelete);
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000); // Hide toast after 3 seconds
    }
    setShowDeleteConfirm(false);
    setAlgorithmToDelete(null);
  };
  const navigate = useNavigate();

  const handleRowClick = (row: any) => {
    if (!isMasterList) {
      navigate(`/algorithms/${row.id}`);
    }
  };

  const renderTableRows = (rows: any[]) => {
    return rows.map((row, index) => (
      <tr
        key={row.id}
        onClick={() => handleRowClick(row)}
        className="hover:bg-base-200 transition-colors duration-200 cursor-pointer"
      >
        {columns.map((column) => {
          if (column.key === "number") {
            return (
              <td key={`${row.id}-${column.key}`}>{startIndex + index + 1}</td>
            );
          }
          if (column.key === "title") {
            return (
              <td key={`${row.id}-${column.key}`}>
                <span className="link link-primary font-medium">
                  {row[column.key]}
                </span>

                <button
                  onClick={(e) => handleReminderClick(e, row)}
                  className="btn btn-ghost btn-xs mr-2"
                >
                  {row.reminder ? (
                    <BellRingIcon className="h-4 w-4 text-primary" />
                  ) : (
                    <BellIcon className="h-4 w-4" />
                  )}
                </button>
              </td>
            );
          }
          if (column.key === "timeSpent") {
            return (
              <td key={`${row.id}-${column.key}`}>
                {row[column.key] ? `${row[column.key]} mins` : "-"}
              </td>
            );
          }
          if (column.key === "group") {
            return null; // Skip rendering the group column as it's now under the title
          }
          return (
            <td key={`${row.id}-${column.key}`}>
              {column.key === "solved" ? (
                <span
                  className={`badge ${row[column.key] ? "badge-success" : "badge-error"}`}
                >
                  {row[column.key] ? "Solved" : "Unsolved"}
                </span>
              ) : column.key === "level" ? (
                <span
                  className={`badge ${getLevelBadgeColor(row[column.key])}`}
                >
                  {row[column.key]}
                </span>
              ) : (
                row[column.key]
              )}
            </td>
          );
        })}
        <td>
          <button
            onClick={(e) => handleDeleteClick(e, row.id)}
            className="btn btn-ghost btn-xs text-error"
          >
            <Trash2 className="h-4 w-4" />
          </button>
        </td>
      </tr>
    ));
  };

  const getLevelBadgeColor = (level: string) => {
    switch (level.toLowerCase()) {
      case "easy":
        return "badge-success";
      case "medium":
        return "badge-warning";
      case "hard":
        return "badge-error";
      default:
        return "badge-info";
    }
  };

  return (
    <div className="p-6 bg-base-200 rounded-xl shadow-xl mt-8 mx-4 mb-8">
      <div className="overflow-x-auto bg-base-100 rounded-xl shadow-lg">
        <table className="table w-full">
          <thead>
            <tr>
              {columns.map((column) => (
                <th
                  key={column.key}
                  onClick={() => column.key !== "number" && onSort(column.key)}
                  style={{
                    cursor: column.key !== "number" ? "pointer" : "default",
                  }}
                >
                  {column.label}{" "}
                  {column.key !== "number" && getSortIcon(column.key)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr
                key={row.id}
                onClick={() => handleRowClick(row)}
                className={`hover:bg-base-200 transition-colors duration-200 ${!isMasterList ? "cursor-pointer" : ""}`}
              >
                {columns.map((column) => (
                  <td key={`${row.id}-${column.key}`}>
                    {renderCell(row, column)}
                  </td>
                ))}
                {!isMasterList && (
                  <td>
                    <button
                      onClick={(e) => handleDeleteClick(e, row.id)}
                      className="btn btn-ghost btn-xs text-error"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {reminderModalOpen && selectedAlgorithm && (
          <ReminderModal
            algorithm={selectedAlgorithm}
            onClose={() => setReminderModalOpen(false)}
            onSetReminder={onSetReminder}
          />
        )}
      </div>
      {reminderModalOpen && selectedAlgorithm && (
        <ReminderModal
          algorithm={selectedAlgorithm}
          onClose={() => setReminderModalOpen(false)}
          onSetReminder={onSetReminder}
        />
      )}
      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-base-100 p-6 rounded-lg shadow-xl">
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p>Do you want to delete this algorithm?</p>
            <div className="flex justify-end space-x-2 mt-4">
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="btn btn-ghost"
              >
                No
              </button>
              <button onClick={confirmDelete} className="btn btn-error">
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
      {showToast && <Toast message="Algorithm deleted successfully" />}
    </div>
  );
};

export default DynamicTable;
