import React from "react";

interface DifficultyStats {
  easy: number;
  medium: number;
  hard: number;
}

interface DifficultyStatsBarProps {
  stats: DifficultyStats;
}

const DifficultyStatsBar: React.FC<DifficultyStatsBarProps> = ({ stats }) => {
  const total = stats.easy + stats.medium + stats.hard;

  const renderBar = (level: "easy" | "medium" | "hard", color: string) => {
    const count = stats[level];
    const percentage = total > 0 ? (count / total) * 100 : 0;

    return (
      <div className="mb-2">
        <div className="flex justify-between mb-1">
          <span className="text-sm font-medium">
            {level.charAt(0).toUpperCase() + level.slice(1)}
          </span>
          <span className="text-sm font-medium">{count}</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
          <div
            className={`h-2.5 rounded-full ${color}`}
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full">
      <div className="text-sm font-medium text-center mb-2">
        Problems Solved: {total}
      </div>
      {renderBar("easy", "bg-success")}
      {renderBar("medium", "bg-warning")}
      {renderBar("hard", "bg-error")}
    </div>
  );
};

export default DifficultyStatsBar;
