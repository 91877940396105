import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { SystemDesign } from "../types/SystemDesign";
import { Helmet } from "react-helmet";
import { systemDesignApi } from "../api/systemDesignApi";
import { FaExpandAlt, FaCompressAlt } from "react-icons/fa";

const companies = [
  "Google",
  "Amazon",
  "Facebook",
  "Apple",
  "Microsoft",
  "Netflix",
  "Uber",
  "Airbnb",
  "Twitter",
  "LinkedIn",
];

const SystemDesignFormPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const [company, setCompany] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [difficulty, setDifficulty] = useState<"Easy" | "Medium" | "Hard">(
    "Medium",
  );
  const [images, setImages] = useState<File[]>([]);
  const [existingImageUrls, setExistingImageUrls] = useState<string[]>([]);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  useEffect(() => {
    if (id) {
      fetchSystemDesign(parseInt(id));
    }
  }, [id]);

  const fetchSystemDesign = async (designId: number) => {
    try {
      const design = await systemDesignApi.getById(designId);
      setCompany(design.company);
      setTitle(design.title);
      setDescription(design.description);
      setDifficulty(design.difficulty);
      setExistingImageUrls(design.images || []);
    } catch (error) {
      console.error("Error fetching system design:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleImageUpload = (files: FileList | null) => {
    if (files && files.length > 0) {
      const newImages = Array.from(files).slice(
        0,
        3 - images.length - existingImageUrls.length,
      );
      setImages([...images, ...newImages]);
    }
  };

  const uploadToPinata = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await axios.post(
        "https://api.pinata.cloud/pinning/pinFileToIPFS",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
            pinata_secret_api_key: process.env.REACT_APP_PINATA_SECRET_API_KEY,
          },
        },
      );

      return `https://gateway.pinata.cloud/ipfs/${res.data.IpfsHash}`;
    } catch (error) {
      console.error("Error uploading to Pinata:", error);
      throw error;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const newImageUrls = await Promise.all(images.map(uploadToPinata));
      const allImageUrls = [...existingImageUrls, ...newImageUrls];

      const designData = {
        company,
        title,
        description,
        difficulty,
        images: allImageUrls,
      };

      if (id) {
        await systemDesignApi.update(parseInt(id), {
          id: parseInt(id),
          ...designData,
        });
      } else {
        await systemDesignApi.create(designData);
      }

      navigate("/system-design");
    } catch (error) {
      console.error("Error saving system design:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const toggleDescriptionExpand = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  return (
    <>
      <Helmet>
        <title>{id ? "Edit" : "Add New"} System Design - AlgoBuddy</title>
        <meta
          name="description"
          content={`${id ? "Edit existing" : "Create a new"} system design entry for your interview preparation.`}
        />
      </Helmet>

      <div
        className={`container mx-auto p-4 ${isDescriptionExpanded ? "max-w-full" : "max-w-4xl"}`}
      >
        <h1 className="text-3xl font-bold mb-6">
          {id ? "Edit" : "Add New"} System Design
        </h1>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="title" className="block text-sm font-medium mb-1">
              Title
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="input input-bordered w-full"
              required
            />
          </div>

          <div className="flex space-x-4">
            <div className="w-1/2">
              <label
                htmlFor="company"
                className="block text-sm font-medium mb-1"
              >
                Company
              </label>
              <select
                id="company"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                className="select select-bordered w-full"
                required
              >
                <option value="">Select a company</option>
                {companies.map((c) => (
                  <option key={c} value={c}>
                    {c}
                  </option>
                ))}
              </select>
            </div>

            <div className="w-1/2">
              <label
                htmlFor="difficulty"
                className="block text-sm font-medium mb-1"
              >
                Difficulty
              </label>
              <select
                id="difficulty"
                value={difficulty}
                onChange={(e) =>
                  setDifficulty(e.target.value as "Easy" | "Medium" | "Hard")
                }
                className="select select-bordered w-full"
              >
                <option value="Easy">Easy</option>
                <option value="Medium">Medium</option>
                <option value="Hard">Hard</option>
              </select>
            </div>
          </div>

          <div
            className={`relative ${isDescriptionExpanded ? "fixed inset-0 z-50 bg-base-100" : ""}`}
          >
            <label
              htmlFor="description"
              className="block text-sm font-medium mb-1"
            >
              Description
            </label>
            {isDescriptionExpanded ? (
              <div className="fixed inset-0 z-50 bg-base-100 pt-4 pb-4 pl-20 pr-4 overflow-auto">
                <textarea
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="textarea textarea-bordered w-full h-[calc(100vh-120px)]"
                  required
                ></textarea>
                <button
                  type="button"
                  onClick={toggleDescriptionExpand}
                  className="btn btn-circle btn-sm absolute top-4 right-4"
                >
                  <FaCompressAlt />
                </button>
              </div>
            ) : (
              <>
                <textarea
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="textarea textarea-bordered w-full h-96"
                  required
                ></textarea>
                <button
                  type="button"
                  onClick={toggleDescriptionExpand}
                  className="btn btn-circle btn-sm absolute top-0 right-0 mt-1 mr-1"
                >
                  <FaExpandAlt />
                </button>
              </>
            )}
          </div>

          <div>
            <label htmlFor="images" className="block text-sm font-medium mb-1">
              Images (Max 3)
            </label>
            <input
              type="file"
              id="images"
              accept="image/*"
              multiple
              onChange={(e) => handleImageUpload(e.target.files)}
              className="file-input file-input-bordered w-full"
            />
            <p className="text-sm mt-1">
              {images.length + existingImageUrls.length}/3 images selected
            </p>
            {existingImageUrls.map((url, index) => (
              <img
                key={index}
                src={url}
                alt={`Existing image ${index + 1}`}
                className="mt-2 w-24 h-24 object-cover inline-block mr-2"
              />
            ))}
          </div>

          <div className="flex justify-end space-x-2 mt-6">
            <button
              type="button"
              onClick={() => navigate("/system-design")}
              className="btn btn-ghost"
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              {id ? "Update" : "Create"} System Design
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SystemDesignFormPage;
