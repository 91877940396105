import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import SignIn from "../components/SignIn";
import SignUp from "../components/SignUp";
import { PlusCircle, Clock, Calendar, HelpCircle } from "lucide-react";
import { Helmet } from "react-helmet";

const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);

  const handleGetStarted = () => {
    if (user) {
      navigate("/algorithms");
    } else {
      setShowSignUp(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>AlgoBuddy - Master Your Technical Interviews</title>
        <meta
          name="description"
          content="Organize, track, and review your interview preparation with AlgoBuddy. Master algorithms and ace your technical interviews."
        />
        <link rel="canonical" href="https://algobuddy.com" />
      </Helmet>

      <div className="bg-gray-900 text-gray-100 min-h-screen">
        <header className="bg-gray-800 shadow-lg">
          <nav className="container mx-auto px-6 py-3 flex justify-between items-center">
            <div className="text-2xl font-bold gradient-text">AlgoBuddy 💻</div>
            <div>
              <button
                onClick={() => setShowSignIn(true)}
                className="text-gray-300 hover:text-white mx-4"
              >
                Sign In
              </button>
              <button
                onClick={() => setShowSignUp(true)}
                className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md transition duration-300"
              >
                Sign Up
              </button>
            </div>
          </nav>
        </header>

        <main className="container mx-auto px-6 py-12">
          <section className="text-center mb-16">
            <h1 className="text-5xl font-bold mb-6 gradient-text">
              Master Your Technical Interviews
            </h1>
            <p className="text-xl text-gray-400 mb-8">
              Organize, track, and review your interview preparation with ease.
            </p>
            <button
              onClick={handleGetStarted}
              className="bg-green-600 hover:bg-green-700 text-white px-8 py-3 rounded-full text-lg transition duration-300 inline-block"
            >
              Get Started
            </button>
          </section>

          <section className="mb-24">
            <div className="flex flex-col md:flex-row items-center mb-24">
              <div className="md:w-1/2 md:pr-8">
                <h2 className="text-3xl font-semibold mb-4 flex items-center text-green-400">
                  <PlusCircle className="mr-2" />
                  Add Questions & Track Progress
                </h2>
                <p className="text-gray-300 text-lg">
                  Easily log questions, solution times, and helpful hints. Build
                  your personal question bank and track your progress over time.
                </p>
              </div>
              <div className="md:w-1/2 mt-8 md:mt-0">
                <img
                  src="/images/add-question.png"
                  alt="Add Question Interface"
                  className="rounded-lg shadow-xl"
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse items-center mb-24">
              <div className="md:w-1/2 md:pl-8">
                <h2 className="text-3xl font-semibold mb-4 flex items-center text-blue-400">
                  <Clock className="mr-2" />
                  Set Smart Reminders
                </h2>
                <p className="text-gray-300 text-lg">
                  Schedule review sessions for optimal retention. Our smart
                  reminder system ensures you never forget to revisit important
                  concepts.
                </p>
              </div>
              <div className="md:w-1/2 mt-8 md:mt-0">
                <img
                  src="/images/smart-reminders.png"
                  alt="Smart Reminders Interface"
                  className="rounded-lg shadow-xl"
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row items-center mb-24">
              <div className="md:w-1/2 md:pr-8">
                <h2 className="text-3xl font-semibold mb-4 flex items-center text-purple-400">
                  <Calendar className="mr-2" />
                  Daily Review Queue
                </h2>
                <p className="text-gray-300 text-lg">
                  Get a curated list of questions to review each day based on
                  your set reminders and learning patterns. Stay organized and
                  focused.
                </p>
              </div>
              <div className="md:w-1/2 mt-8 md:mt-0">
                <img
                  src="/images/daily-review.png"
                  alt="Daily Review Queue"
                  className="rounded-lg shadow-xl"
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse items-center">
              <div className="md:w-1/2 md:pl-8">
                <h2 className="text-3xl font-semibold mb-4 flex items-center text-yellow-400">
                  <HelpCircle className="mr-2" />
                  Progressive Hint System
                </h2>
                <p className="text-gray-300 text-lg">
                  Access hints one at a time, maintaining the challenge while
                  providing support when needed. Gradually build your
                  problem-solving skills.
                </p>
              </div>
              <div className="md:w-1/2 mt-8 md:mt-0">
                <img
                  src="/images/hint-system.png"
                  alt="Progressive Hint System"
                  className="rounded-lg shadow-xl"
                />
              </div>
            </div>
          </section>
        </main>

        {showSignIn && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-gray-800 p-8 rounded-lg w-96">
              <SignIn onClose={() => setShowSignIn(false)} />
            </div>
          </div>
        )}

        {showSignUp && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-gray-800 p-8 rounded-lg w-96">
              <SignUp onClose={() => setShowSignUp(false)} />
            </div>
          </div>
        )}

        <footer className="bg-gray-800 text-gray-400 py-8">
          <div className="container mx-auto px-6 text-center">
            <p>&copy; 2024 AlgoBuddy. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default LandingPage;
