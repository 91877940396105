import React, { useState } from "react";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  getIdToken,
} from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

interface SignInProps {
  onClose: () => void;
}

const SignIn: React.FC<SignInProps> = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const navigate = useNavigate();

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );

      const user = userCredential.user;

      // Get the ID token
      const token = await getIdToken(user);

      // Store the token in localStorage
      localStorage.setItem("bearerToken", token);
      navigate("/algorithms");
      onClose();
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError("Please enter your email address.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setResetEmailSent(true);
      setError("");
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <div className="p-6 bg-base-200 rounded-lg">
      <h2 className="text-2xl font-bold mb-4 text-center">Sign In</h2>
      <form onSubmit={handleSignIn} className="space-y-4">
        <div>
          <label htmlFor="email" className="block mb-1">
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input input-bordered w-full"
            required
          />
        </div>
        <div>
          <label htmlFor="password" className="block mb-1">
            Password
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input input-bordered w-full"
            required
          />
        </div>
        {error && <div className="text-error">{error}</div>}
        {resetEmailSent && (
          <div className="text-success">
            Password reset email sent. Please check your inbox.
          </div>
        )}
        <button type="submit" className="btn btn-primary w-full">
          Sign In
        </button>
      </form>
      <div className="mt-4 text-center">
        <button onClick={handleForgotPassword} className="btn btn-link">
          Forgot Password?
        </button>
      </div>
      <button onClick={onClose} className="btn btn-ghost mt-4 w-full">
        Close
      </button>
    </div>
  );
};

export default SignIn;
