import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./App.css";
import AlgorithmsPage from "./pages/AlgorithmsPage";
import HistoryPage from "./pages/HistoryPage";
import AlgorithmsDetailsPage from "./pages/AlgorithmsDetailsPage";
import { AuthProvider, useAuth } from "./AuthContext";
import AlgorithmEntryForm from "./pages/AlgorithmEntryForm";
import { AlgorithmProvider, useAlgorithm } from "./pages/AlgorithmContext";
import { MessageProvider } from "./pages/MessageContext";
import MessageDisplay from "./components/MessageDisplay";
import LeftNavPane from "./components/LeftNavPane";
import LandingPage from "./pages/LandingPage";
import UserProfile from "./components/UserProfile";
import FeedbackButton from "./components/FeedbackButton";
import { Helmet } from "react-helmet";
import { logPageView } from "./analytics";
import SystemDesignPage from "./pages/SystemDesignPage";
import SystemDesignDetailPage from "./pages/SystemDesignDetailPage";
import SystemDesignFormPage from "./pages/SystemDesignFormPage";

const PrivateRoute: React.FC<{ element: React.ReactElement }> = ({
  element,
}) => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !user) {
      navigate("/");
    }
  }, [user, loading, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return user ? element : <Navigate to="/signin" replace />;
};

const AppContent: React.FC = () => {
  const { user, loading } = useAuth();
  const { algorithms } = useAlgorithm();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();

  const [difficultyStats, setDifficultyStats] = useState({
    easy: 0,
    medium: 0,
    hard: 0,
  });

  useEffect(() => {
    const stats = algorithms.reduce(
      (acc, algo) => {
        if (algo.solved) {
          if (algo.level.toLowerCase() === "easy") acc.easy++;
          else if (algo.level.toLowerCase() === "medium") acc.medium++;
          else if (algo.level.toLowerCase() === "hard") acc.hard++;
        }
        return acc;
      },
      { easy: 0, medium: 0, hard: 0 },
    );
    setDifficultyStats(stats);
  }, [algorithms]);

  useEffect(() => {
    logPageView(location.pathname);
  }, [location]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <MessageDisplay />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route
          path="/*"
          element={
            <div className="flex h-screen">
              <div className="relative">
                <LeftNavPane
                  difficultyStats={difficultyStats}
                  isCollapsed={isCollapsed}
                  setIsCollapsed={setIsCollapsed}
                />
                {user && <UserProfile isCollapsed={isCollapsed} />}
              </div>
              <div className="flex-1 overflow-x-hidden">
                <main className="p-4">
                  <Routes>
                    <Route
                      path="/algorithms"
                      element={<PrivateRoute element={<AlgorithmsPage />} />}
                    />
                    <Route
                      path="/history"
                      element={<PrivateRoute element={<HistoryPage />} />}
                    />
                    <Route
                      path="/algorithms/:id"
                      element={
                        <PrivateRoute element={<AlgorithmsDetailsPage />} />
                      }
                    />
                    <Route
                      path="/algorithms/new"
                      element={
                        <PrivateRoute element={<AlgorithmEntryForm />} />
                      }
                    />
                    <Route
                      path="/algorithms/:id/edit"
                      element={
                        <PrivateRoute element={<AlgorithmEntryForm />} />
                      }
                    />
                    {/* Redirect to algorithms page if no other route matches */}
                    <Route
                      path="*"
                      element={<Navigate to="/algorithms" replace />}
                    />
                    <Route
                      path="/system-design"
                      element={<PrivateRoute element={<SystemDesignPage />} />}
                    />
                    <Route
                      path="/system-design/:id"
                      element={
                        <PrivateRoute element={<SystemDesignDetailPage />} />
                      }
                    />
                    <Route
                      path="/system-design/new"
                      element={<SystemDesignFormPage />}
                    />
                    <Route
                      path="/system-design/edit/:id"
                      element={<SystemDesignFormPage />}
                    />
                  </Routes>
                </main>
              </div>
              <FeedbackButton />
            </div>
          }
        />
      </Routes>
    </>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <Helmet>
        <title>AlgoBuddy - Master Your Technical Interviews</title>
        <meta
          name="description"
          content="Organize, track, and review your interview preparation with AlgoBuddy. Master algorithms and ace your technical interviews."
        />
        <link rel="canonical" href="https://algobuddy.com" />
      </Helmet>

      <AuthProvider>
        <MessageProvider>
          <AlgorithmProvider>
            <AppContent />
          </AlgorithmProvider>
        </MessageProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
