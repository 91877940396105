import React, { useEffect } from "react";
import { useMessage } from "../pages/MessageContext";

const MessageDisplay: React.FC = () => {
  const { message, setMessage } = useMessage();

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage(null);
      }, 5000); // Hide message after 5 seconds

      return () => clearTimeout(timer);
    }
  }, [message, setMessage]);

  if (!message) return null;

  const getBgColor = () => {
    switch (message.type) {
      case "success":
        return "bg-green-500";
      case "error":
        return "bg-red-500";
      case "info":
        return "bg-blue-500";
      default:
        return "bg-gray-500";
    }
  };

  return (
    <div
      className={`fixed top-4 right-4 ${getBgColor()} text-white p-4 rounded shadow-lg`}
    >
      {message.text}
    </div>
  );
};

export default MessageDisplay;
