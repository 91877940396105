import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  ReactNode,
} from "react";
import { useMessage } from "./MessageContext";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { logButtonClick } from "../analytics";

interface Algorithm {
  id: number;
  title: string;
  tag: string[];
  timeSpent: string;
  level: string;
  solved: boolean;
  hints: string[];
  description: string;
  codeSnippet: string;
  reminder: Date | null;
}

interface AlgorithmContextType {
  algorithms: Algorithm[];
  addAlgorithm: (algorithm: Omit<Algorithm, "id">) => Promise<void>;
  addAlgorithmFromMaster: (masterId: number) => Promise<void>;
  deleteAlgorithms: (ids: number[]) => Promise<void>;
  updateAlgorithm: (updatedAlgorithm: Algorithm) => Promise<void>;
  fetchAlgorithms: () => Promise<void>;
}

const AlgorithmContext = createContext<AlgorithmContextType | undefined>(
  undefined,
);

let API_URL: string;
let API_URL_MASTER: string;
if (process.env.NODE_ENV === "production") {
  API_URL = process.env.REACT_APP_API_URL_PRODUCTION || "";
} else {
  console.log("dev env..");
  API_URL = process.env.REACT_APP_API_URL_DEVELOPMENT || "";
  API_URL_MASTER = process.env.REACT_APP_API_URL_MASTER_DEVELOPMENT || "";
}
console.log(process.env.NODE_ENV);

console.log(API_URL);

if (!API_URL) {
  throw new Error("API_URL is not defined in environment variables");
}

// Helper function to create headers with bearer token
const createHeaders = async () => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error("User not authenticated");
  }

  const token = await user.getIdToken();

  const headers = new Headers({
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  });
  return headers;
};

export const AlgorithmProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [algorithms, setAlgorithms] = useState<Algorithm[]>([]);
  const { setMessage } = useMessage();
  const navigate = useNavigate();

  const fetchAlgorithms = useCallback(async () => {
    try {
      const headers = await createHeaders();
      const response = await fetch(API_URL, { headers });
      if (response.status === 401) {
        setMessage({
          text: "Your session has expired. Please sign in again.",
          type: "error",
        });
        navigate("/signin");
        return;
      }
      if (!response.ok) throw new Error("Failed to fetch algorithms");

      const data = await response.json();
      setAlgorithms(data);
    } catch (error) {
      console.error("Error fetching algorithms:", error);
    }
  }, [setMessage, navigate]);

  const addAlgorithmFromMaster = useCallback(
    async (masterId: number) => {
      try {
        const headers = await createHeaders();
        logButtonClick("add_algo_from_master");
        const response = await fetch(`${API_URL}/add-from-master/${masterId}`, {
          method: "POST",
          headers,
        });

        if (!response.ok)
          throw new Error("Failed to add algorithm from master list");

        await fetchAlgorithms(); // Refresh the list after adding
        setMessage({
          text: "Algorithm added to your list successfully!",
          type: "success",
        });
      } catch (error) {
        console.error("Error adding algorithm from master list:", error);
        setMessage({
          text: "Failed to add algorithm to your list. Please try again.",
          type: "error",
        });
        throw error;
      }
    },
    [fetchAlgorithms, setMessage],
  );

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchAlgorithms();
      } else {
        setAlgorithms([]);
      }
    });

    return () => unsubscribe();
  }, [fetchAlgorithms]);

  const addAlgorithm = async (algorithm: Omit<Algorithm, "id">) => {
    try {
      const headers = await createHeaders();
      const response = await fetch(API_URL, {
        method: "POST",
        headers,
        body: JSON.stringify(algorithm),
      });
      if (!response.ok) throw new Error("Failed to add algorithm");
      logButtonClick("add_algorithm");
      await fetchAlgorithms();
    } catch (error) {
      console.error("Error adding algorithm:", error);
    }
  };

  const deleteAlgorithms = async (ids: number[]) => {
    try {
      const headers = await createHeaders();
      await Promise.all(
        ids.map((id) =>
          fetch(`${API_URL}/${id}`, {
            method: "DELETE",
            headers,
          }),
        ),
      );
      await fetchAlgorithms();
    } catch (error) {
      console.error("Error deleting algorithms:", error);
    }
  };

  const updateAlgorithm = useCallback(
    async (updatedAlgorithm: Algorithm) => {
      try {
        const headers = await createHeaders();
        const response = await fetch(`${API_URL}/${updatedAlgorithm.id}`, {
          method: "PUT",
          headers,
          body: JSON.stringify({
            ...updatedAlgorithm,
          }),
        });

        if (!response.ok) throw new Error("Failed to update algorithm");
        // Update the algorithm locally
        setAlgorithms((prevAlgorithms) =>
          prevAlgorithms.map((algo) =>
            algo.id === updatedAlgorithm.id ? updatedAlgorithm : algo,
          ),
        );
      } catch (error) {
        console.error("Error updating algorithm:", error);
        setMessage({
          text: "Failed to update algorithm. Please try again.",
          type: "error",
        });
      }
    },
    [setMessage],
  );

  return (
    <AlgorithmContext.Provider
      value={{
        algorithms,
        addAlgorithm,
        addAlgorithmFromMaster,
        deleteAlgorithms,
        updateAlgorithm,
        fetchAlgorithms,
      }}
    >
      {children}
    </AlgorithmContext.Provider>
  );
};

export const useAlgorithm = () => {
  const context = useContext(AlgorithmContext);
  if (context === undefined) {
    throw new Error("useAlgorithm must be used within an AlgorithmProvider");
  }
  return context;
};
