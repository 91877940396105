import React, { useState, useMemo, useEffect, useCallback } from "react";
import DynamicTable from "../components/DynamicTable";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { useAlgorithm } from "../pages/AlgorithmContext";
import { Plus } from "lucide-react";
import { Helmet } from "react-helmet";
import Spinner from "../components/Spinner";
import MasterAlgorithmList from "../components/MasterAlgorithmList";

const ITEMS_PER_PAGE = 10;

const AlgorithmsPage: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { algorithms, deleteAlgorithms, updateAlgorithm, fetchAlgorithms } =
    useAlgorithm();
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState<
    "all" | "reminders" | "master-list"
  >("all");
  const [isLoading, setIsLoading] = useState(true);

  const [columns] = useState([
    { key: "number", label: "#" },
    { key: "title", label: "Title" },
    { key: "solved", label: "Solved" },
    { key: "timeSpent", label: "Time Spent" },
    { key: "level", label: "Level" },
  ]);

  useEffect(() => {
    const loadAlgorithms = async () => {
      setIsLoading(true);
      try {
        await fetchAlgorithms();
      } catch (error) {
        console.error("Error fetching algorithms:", error);
        // Optionally, you can set an error state here and show an error message to the user
      } finally {
        setIsLoading(false);
      }
    };
    loadAlgorithms();
  }, [fetchAlgorithms]);

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc" | null>(
    null,
  );
  const [showSignInPrompt, setShowSignInPrompt] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const handleSort = useCallback((key: string) => {
    if (sortColumn === key) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(key);
      setSortDirection("asc");
    }
  }, []);

  const handleDelete = useCallback(
    (id: number) => {
      deleteAlgorithms([id]);
    },
    [deleteAlgorithms],
  );

  const handleSetReminder = useCallback(
    async (id: number, date: Date | null) => {
      const algorithmToUpdate = algorithms.find((algo) => algo.id === id);
      if (algorithmToUpdate) {
        const updatedAlgorithm = { ...algorithmToUpdate, reminder: date };
        await updateAlgorithm(updatedAlgorithm);
        await fetchAlgorithms(); // Refresh the algorithms list after updating
      }
    },
    [algorithms, updateAlgorithm, fetchAlgorithms],
  );

  const filteredAndSortedAlgorithms = useMemo(() => {
    let filtered = algorithms;

    if (activeTab === "reminders") {
      filtered = filtered.filter((algo) => algo.reminder != null);
    }

    filtered = filtered.filter((algo) =>
      algo.title.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const dueToday = filtered.filter((algo) => {
      if (!algo.reminder) return false;
      const reminderDate = new Date(algo.reminder!);
      reminderDate.setHours(0, 0, 0, 0);
      return reminderDate <= today;
    });

    const upcoming = filtered.filter((algo) => {
      if (!algo.reminder) return false;
      const reminderDate = new Date(algo.reminder!);
      reminderDate.setHours(0, 0, 0, 0);
      return reminderDate > today;
    });

    const noReminder = filtered.filter((algo) => algo.reminder == null);

    return { dueToday, upcoming, noReminder };
  }, [algorithms, searchTerm, activeTab]);

  const paginatedAlgorithms = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return {
      dueToday: filteredAndSortedAlgorithms.dueToday.slice(
        startIndex,
        endIndex,
      ),
      upcoming: filteredAndSortedAlgorithms.upcoming.slice(
        startIndex,
        endIndex,
      ),
      noReminder: filteredAndSortedAlgorithms.noReminder.slice(
        startIndex,
        endIndex,
      ),
    };
  }, [filteredAndSortedAlgorithms, currentPage]);

  const totalItems = useMemo(() => {
    if (activeTab === "all") {
      return (
        filteredAndSortedAlgorithms.dueToday.length +
        filteredAndSortedAlgorithms.upcoming.length +
        filteredAndSortedAlgorithms.noReminder.length
      );
    } else {
      return (
        filteredAndSortedAlgorithms.dueToday.length +
        filteredAndSortedAlgorithms.upcoming.length
      );
    }
  }, [activeTab, filteredAndSortedAlgorithms]);

  const totalPages = useMemo(() => {
    return Math.ceil(totalItems / ITEMS_PER_PAGE);
  }, [totalItems]);

  const showPagination = totalItems > ITEMS_PER_PAGE;

  const renderPagination = () => {
    if (!showPagination) return null;

    return (
      <div className="flex justify-center mt-4">
        <div className="btn-group">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              className={`btn btn-sm ${currentPage === page ? "btn-active" : ""}`}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </button>
          ))}
        </div>
      </div>
    );
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  if (isLoading) {
    return <Spinner />;
  }

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "AlgoBuddy Algorithms",
    description:
      "Practice and track your progress on coding algorithms for technical interviews.",
    publisher: {
      "@type": "Organization",
      name: "AlgoBuddy",
    },
  };

  return (
    <>
      <Helmet>
        <title>Algorithms - AlgoBuddy</title>
        <meta
          name="description"
          content="Practice and track your progress on coding algorithms for technical interviews with AlgoBuddy."
        />
        <link rel="canonical" href="https://algobuddy.com/algorithms" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <main className="p-4 bg-dark-bg text-dark-text">
        <div className="bg-base-200 rounded-lg overflow-hidden">
          <div className="flex border-b border-base-300">
            <button
              className={`flex-1 py-3 px-4 text-lg font-semibold transition-colors duration-200 ease-in-out
                      ${
                        activeTab === "all"
                          ? "bg-base-100 text-primary"
                          : "hover:bg-base-300"
                      }`}
              onClick={() => setActiveTab("all")}
            >
              My Algos
            </button>
            <button
              className={`flex-1 py-3 px-4 text-lg font-semibold transition-colors duration-200 ease-in-out
                      ${
                        activeTab === "reminders"
                          ? "bg-base-100 text-primary"
                          : "hover:bg-base-300"
                      }`}
              onClick={() => setActiveTab("reminders")}
            >
              Reminders
            </button>
            <button
              className={`flex-1 py-3 px-4 text-lg font-semibold transition-colors duration-200 ease-in-out
                                  ${activeTab === "master-list" ? "bg-base-100 text-primary" : "hover:bg-base-300"}`}
              onClick={() => setActiveTab("master-list")}
            >
              Blind (75)
            </button>
          </div>

          <div className="p-4 bg-base-100">
            {activeTab === "master-list" && <MasterAlgorithmList />}

            {activeTab === "all" && (
              <div className="flex justify-between items-center mb-4">
                <div className="form-control flex-grow mr-4">
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Search by title..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="input input-bordered w-mid"
                    />
                  </div>
                </div>
                <button
                  onClick={() => navigate("/algorithms/new")}
                  className={`btn btn-primary ${!user ? "btn-disabled" : ""}`}
                >
                  <Plus className="h-5 w-5 mr-2" />
                  Add Question
                </button>
              </div>
            )}

            {activeTab === "reminders" && (
              <>
                {paginatedAlgorithms.dueToday.length > 0 && (
                  <>
                    <h2 className="text-xl font-bold mt-2 mb-4">Due Today</h2>
                    <DynamicTable
                      data={paginatedAlgorithms.dueToday}
                      columns={columns}
                      startIndex={0}
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                      onSort={handleSort}
                      sortColumn={sortColumn}
                      sortDirection={sortDirection}
                      onSetReminder={handleSetReminder}
                      onDelete={handleDelete}
                    />
                  </>
                )}

                {paginatedAlgorithms.upcoming.length > 0 && (
                  <>
                    <h2 className="text-xl font-bold mt-6 mb-4">Upcoming</h2>
                    <DynamicTable
                      data={paginatedAlgorithms.upcoming}
                      columns={columns}
                      startIndex={filteredAndSortedAlgorithms.dueToday.length}
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                      onSort={handleSort}
                      sortColumn={sortColumn}
                      sortDirection={sortDirection}
                      onSetReminder={handleSetReminder}
                      onDelete={handleDelete}
                    />
                  </>
                )}
              </>
            )}

            {activeTab === "all" && (
              <DynamicTable
                data={[
                  ...paginatedAlgorithms.dueToday,
                  ...paginatedAlgorithms.upcoming,
                  ...paginatedAlgorithms.noReminder,
                ]}
                columns={columns}
                startIndex={(currentPage - 1) * ITEMS_PER_PAGE}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                onSort={handleSort}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                onSetReminder={handleSetReminder}
                onDelete={(id: number) => deleteAlgorithms([id])}
              />
            )}
            {renderPagination()}
          </div>
        </div>

        {showSignInPrompt && !user && (
          <div className="sign-in-prompt space-x-4 mt-4">
            <p>Please Signup/SignIn to save your progress.</p>
            <Link to="/signup" className="btn btn-primary rounded-full">
              Sign Up
            </Link>
            <Link to="/signin" className="btn btn-primary rounded-full">
              Sign In
            </Link>
          </div>
        )}
      </main>
    </>
  );
};

export default AlgorithmsPage;
