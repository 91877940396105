import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { systemDesignApi } from "../api/systemDesignApi";
import { SystemDesign } from "../types/SystemDesign";
import { Trash2 } from "lucide-react";

const SystemDesignPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [designs, setDesigns] = useState<SystemDesign[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchDesigns();
  }, []);

  const fetchDesigns = async () => {
    setIsLoading(true);
    try {
      const fetchedDesigns = await systemDesignApi.getAll();
      setDesigns(fetchedDesigns);
    } catch (error) {
      console.error("Error fetching system designs:", error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsLoading(false);
    }
  };

  const filteredDesigns = designs.filter(
    (design) =>
      design.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      design.company.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleDelete = async (id: number, event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent row click event from triggering
    if (window.confirm("Are you sure you want to delete this system design?")) {
      try {
        await systemDesignApi.delete(id);
        setDesigns(designs.filter((design) => design.id !== id));
      } catch (error) {
        console.error("Error deleting system design:", error);
        // Handle error (e.g., show error message to user)
      }
    }
  };

  const getDifficultyBadgeColor = (difficulty: string) => {
    switch (difficulty.toLowerCase()) {
      case "easy":
        return "badge-success";
      case "medium":
        return "badge-warning";
      case "hard":
        return "badge-error";
      default:
        return "badge-info";
    }
  };

  const handleRowClick = (id: number) => {
    navigate(`/system-design/edit/${id}`);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>System Design Questions - AlgoBuddy</title>
        <meta
          name="description"
          content="Practice and learn system design questions for technical interviews with AlgoBuddy."
        />
      </Helmet>

      <main className="p-4 bg-dark-bg text-dark-text">
        <div className="bg-base-200 rounded-lg overflow-hidden">
          <div className="p-4 bg-base-100">
            <h1 className="text-2xl font-bold mb-4">System Design Questions</h1>
            <div className="flex justify-between items-center mb-4">
              <div className="form-control w-64 mr-4">
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="input input-bordered w-full"
                  />
                </div>
              </div>
              <button
                onClick={() => navigate("/system-design/new")}
                className="btn btn-primary"
              >
                Add Question
              </button>
            </div>

            <div className="overflow-x-auto">
              <table className="table w-full">
                <thead>
                  <tr>
                    <th>Company</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Difficulty</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredDesigns.map((design) => (
                    <tr
                      key={design.id}
                      onClick={() => handleRowClick(design.id)}
                      className="hover:bg-base-200 transition-colors duration-200 cursor-pointer"
                    >
                      <td>{design.company}</td>
                      <td>{design.title}</td>
                      <td>{design.description.substring(0, 100)}...</td>
                      <td>
                        <span
                          className={`badge ${getDifficultyBadgeColor(design.difficulty)}`}
                        >
                          {design.difficulty}
                        </span>
                      </td>
                      <td>
                        <button
                          onClick={(e) => handleDelete(design.id, e)}
                          className="btn btn-sm btn-ghost text-error"
                        >
                          <Trash2 />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SystemDesignPage;
