import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAlgorithm } from "./AlgorithmContext";
import { Helmet } from "react-helmet";
import { logAlgorithmSolved } from "../analytics";

interface AlgorithmData {
  id: number;
  title: string;
  tag: string[];
  timeSpent: string;
  level: string;
  solved: boolean;
  description: string;
  codeSnippet: string;
  hints: string[];
  reminder: Date | null;
}

const AlgorithmsDetailsPage: React.FC = () => {
  const { id } = useParams();
  const algoId = Number(id);
  const [algorithmData, setAlgorithmData] = useState<AlgorithmData | null>(
    null,
  );
  const { algorithms, fetchAlgorithms } = useAlgorithm();
  const [revealedHints, setRevealedHints] = useState<number>(0);
  const [showCode, setShowCode] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAlgorithms();
  }, [fetchAlgorithms]);

  useEffect(() => {
    if (id && algorithms.length > 0) {
      const algorithm = algorithms.find((algo) => algo.id === algoId);
      if (algorithm) {
        // Ensure hints is always an array
        setAlgorithmData({
          ...algorithm,
          hints: Array.isArray(algorithm.hints)
            ? algorithm.hints
            : typeof algorithm.hints === "string"
              ? (algorithm.hints as string)
                  .split(",")
                  .map((hint) => hint.trim())
              : [],
        });
      } else {
        console.log("Algorithm not found for id:", algoId);
      }
    }
  }, [id, algorithms, algoId]);

  if (!algorithmData) {
    return <div className="p-4">Loading...</div>;
  }

  const revealNextHint = () => {
    if (revealedHints < (algorithmData.hints?.length || 0)) {
      setRevealedHints((prevRevealed) => prevRevealed + 1);
    }
  };

  const structuredData = algorithmData
    ? {
        "@context": "https://schema.org",
        "@type": "TechArticle",
        headline: algorithmData.title,
        description: algorithmData.description,
        articleBody: algorithmData.codeSnippet,
        author: {
          "@type": "Organization",
          name: "AlgoBuddy",
        },
      }
    : null;

  return (
    <>
      <Helmet>
        <title>{`${algorithmData.title} - AlgoBuddy`}</title>
        <meta
          name="description"
          content={`Practice ${algorithmData.title} algorithm. Difficulty: ${algorithmData.level}. ${algorithmData.description.substring(0, 150)}...`}
        />
        <link
          rel="canonical"
          href={`https://algobuddy.com/algorithms/${algorithmData.id}`}
        />
        {structuredData && (
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        )}
      </Helmet>

      <div className="relative">
        <button
          onClick={() => navigate("/algorithms")}
          className="btn btn-circle btn-outline absolute top-4 left-4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>

        <div className="container mx-auto p-4 max-w-4xl pt-16">
          <h1 className="text-3xl font-bold mb-6 text-center">
            {algorithmData.title}
          </h1>

          <div className="card bg-base-200 shadow-xl mb-6">
            <div className="card-body">
              <h2 className="card-title">Problem Description</h2>
              <p>{algorithmData.description}</p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="card bg-base-200 shadow-xl h-full">
              <div className="card-body">
                <h2 className="card-title">Basic Information</h2>
                <p>
                  <strong>Tag:</strong>{" "}
                  {algorithmData.tag && algorithmData.tag.length > 0 ? (
                    algorithmData.tag.map((tag, index) => (
                      <span
                        key={index}
                        className="badge badge-primary badge-lg"
                      >
                        {tag}
                      </span>
                    ))
                  ) : (
                    <span>No tags available</span>
                  )}
                </p>
                <p>
                  <strong>Time Spent:</strong> {algorithmData.timeSpent}
                </p>
                <p>
                  <strong>Level:</strong>{" "}
                  <span
                    className={`badge ${getLevelBadgeColor(algorithmData.level)}`}
                  >
                    {algorithmData.level}
                  </span>
                </p>
                <p>
                  <strong>Solved:</strong>{" "}
                  <span
                    className={`badge ${algorithmData.solved ? "badge-success" : "badge-error"}`}
                  >
                    {algorithmData.solved ? "Yes" : "No"}
                  </span>
                </p>
              </div>
            </div>

            <div className="card bg-base-200 shadow-xl">
              <div className="card-body">
                <h2 className="card-title">Hints</h2>
                <div className="space-y-2">
                  {algorithmData.hints.length > 0 ? (
                    algorithmData.hints
                      .slice(0, revealedHints)
                      .map((hint, index) => (
                        <div key={index} className="alert alert-info">
                          <span>{hint}</span>
                        </div>
                      ))
                  ) : (
                    <p>No hints available for this algorithm.</p>
                  )}
                </div>
                {algorithmData?.hints &&
                  revealedHints < algorithmData.hints.length && (
                    <button
                      onClick={revealNextHint}
                      className="btn btn-primary mt-4"
                    >
                      Reveal Next Hint
                    </button>
                  )}
              </div>
            </div>
          </div>

          <div className="card bg-base-200 shadow-xl mt-6">
            <div className="card-body">
              <h2 className="card-title">Code Snippet</h2>
              <button
                onClick={() => setShowCode(!showCode)}
                className="btn btn-primary mb-4"
              >
                {showCode ? "Hide Code" : "Show Code"}
              </button>
              {showCode && (
                <>
                  {algorithmData.codeSnippet ? (
                    <pre className="bg-base-300 text-base-content p-4 rounded-md overflow-auto">
                      <code>{algorithmData.codeSnippet}</code>
                    </pre>
                  ) : (
                    <p className="text-warning">
                      No code snippet available for this algorithm.
                    </p>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="flex justify-end space-x-2 mt-6">
            <button
              onClick={() => navigate(`/algorithms/${id}/edit`)}
              className="btn btn-primary"
            >
              Edit
            </button>
            <button
              onClick={() => navigate("/algorithms")}
              className="btn btn-outline"
            >
              Back to List
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const getLevelBadgeColor = (level: string) => {
  switch (level.toLowerCase()) {
    case "easy":
      return "badge-success";
    case "medium":
      return "badge-warning";
    case "hard":
      return "badge-error";
    default:
      return "badge-info";
  }
};

export default AlgorithmsDetailsPage;
