import InteractiveWhiteboardComponent from '../components/Whiteboard';
import React from 'react';

const HistoryPage: React.FC = () => {
  return (
    <div>
      <h2>History</h2>
      <p>This page will display your learning history.</p>
    </div>
    
  );
};

export default HistoryPage;