import React from "react";
import { Link } from "react-router-dom";
import DifficultyStatsBar from "./DifficultyStatsBar";
import { Menu } from "lucide-react";

interface LeftNavPaneProps {
  difficultyStats: {
    easy: number;
    medium: number;
    hard: number;
  };
  isCollapsed: boolean;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeftNavPane: React.FC<LeftNavPaneProps> = ({
  difficultyStats,
  isCollapsed,
  setIsCollapsed,
}) => {
  return (
    <div
      className={`bg-base-200 h-screen flex flex-col transition-all duration-300 ${
        isCollapsed ? "w-16" : "w-64"
      }`}
    >
      <div className="flex-grow p-4 overflow-y-auto">
        <button
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="self-end mb-4"
        >
          {isCollapsed ? (
            <div className="text-2xl font-bold gradient-text">💻</div>
          ) : (
            <Menu className="h-6 w-6" />
          )}
        </button>
        <nav className="mb-8">
          <ul className="space-y-2">
            <li>
              <Link
                to="/algorithms"
                className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                {isCollapsed ? "📒" : "Algorithms"}
              </Link>
            </li>
            <li>
              <Link
                to="/system-design"
                className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                {isCollapsed ? "🏗️" : <>System Design</>}
              </Link>
            </li>
            {/* Add more navigation items as needed */}
          </ul>
        </nav>
        {!isCollapsed && <DifficultyStatsBar stats={difficultyStats} />}
      </div>
      {/* Add padding at the bottom to prevent overlap with UserProfile */}
      <div className="h-20"></div>
    </div>
  );
};

export default LeftNavPane;
