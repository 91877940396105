import { analytics } from "./firebase";
import { logEvent } from "firebase/analytics";

export const logPageView = (pageName: string) => {
  logEvent(analytics, "page_view", {
    page_name: pageName,
    page_location: window.location.href,
    page_path: window.location.pathname,
  });
};

export const logButtonClick = (buttonName: string) => {
  logEvent(analytics, "button_click", {
    button_name: buttonName,
  });
};

export const logAlgorithmSolved = (
  algorithmId: number,
  algorithmName: string,
) => {
  logEvent(analytics, "algorithm_solved", {
    algorithm_id: algorithmId,
    algorithm_name: algorithmName,
  });
};
