import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAlgorithm } from "../pages/AlgorithmContext";

interface AlgorithmEntry {
  title: string;
  tag: string[];
  timeSpent: string;
  level: string;
  solved: boolean;
  description: string;
  codeSnippet: string;
  hints: string[];
  reminder: Date | null;
}

const tagOptions = [
  "Array",
  "Search",
  "Sort",
  "Graph",
  "Tree",
  "DP",
  "Pointer",
  "LinkedList",
  "Stack",
  "Queue",
  "Heap/PriorityQueue",
  "HashMap",
  "Other",
];

const AlgorithmEntryForm: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const algoId = Number(id);
  const { addAlgorithm, updateAlgorithm, algorithms } = useAlgorithm();
  const [codeHeight, setCodeHeight] = useState("600px");

  const [entry, setEntry] = useState<AlgorithmEntry>({
    title: "",
    tag: [],
    timeSpent: "",
    level: "",
    solved: false,
    description: "",
    codeSnippet: "",
    hints: [],
    reminder: new Date(),
  });
  const [tagInput, setTagInput] = useState("");

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value, type } = e.target;
    setEntry((prev) => ({
      ...prev,
      [name]:
        type === "checkbox" ? (e.target as HTMLInputElement).checked : value,
    }));
  };

  const handleCodeResize = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = e.target;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
    setCodeHeight(`${textarea.scrollHeight}px`);
  };

  const addTag = (tag: string) => {
    if (tag && !entry.tag.includes(tag)) {
      setEntry((prev) => ({ ...prev, tag: [...prev.tag, tag] }));
      setTagInput("");
    }
  };

  const removeTag = (tagToRemove: string) => {
    setEntry((prev) => ({
      ...prev,
      tag: prev.tag.filter((tag) => tag !== tagToRemove),
    }));
  };

  useEffect(() => {
    if (id) {
      const algorithm = algorithms.find((algo) => algo.id === algoId);
      if (algorithm) {
        setEntry(algorithm);
      }
    }
  }, [id, algorithms, algoId]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      let submissionData = { ...entry };

      // If no tag is selected, set it to "Other"
      if (submissionData.tag.length === 0) {
        submissionData.tag = ["Other"];
      }

      if (id) {
        await updateAlgorithm({ ...submissionData, id: algoId });
      } else {
        await addAlgorithm(submissionData);
      }
      navigate("/algorithms");
    } catch (error) {
      console.error("Error saving algorithm:", error);
      // Optionally, you can add error handling here (e.g., showing an error message to the user)
    }
  };

  // Generate time options in 5-minute intervals up to 60 minutes
  const timeOptions = Array.from({ length: 12 }, (_, i) => (i + 1) * 5);

  return (
    <div className="relative min-h-screen flex flex-col">
      <button
        onClick={() => navigate("/algorithms")}
        className="btn btn-circle btn-outline absolute top-4 left-4 z-10"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>

      <div className="container mx-auto p-4 flex-grow">
        <h1 className="text-3xl font-bold mb-6 text-center">
          {id ? "Edit" : "Add"} Algorithm Question
        </h1>

        <form
          id="algorithm-form"
          onSubmit={handleSubmit}
          className="flex flex-col md:flex-row gap-6"
        >
          {/* Left side: Basic Information and Problem Details */}
          <div className="lg:w-1/2 space-y-6">
            {/* Basic Information Card */}
            <div className="card bg-base-200 shadow-xl">
              <div className="card-body">
                <h2 className="card-title">Basic Information</h2>
                <div className="form-control">
                  <label htmlFor="title" className="label">
                    <span className="label-text">Title*</span>
                  </label>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    value={entry.title}
                    onChange={handleInputChange}
                    className="input input-bordered w-full"
                    required
                  />
                </div>
                <div className="form-control">
                  <label htmlFor="description" className="label">
                    <span className="label-text">Description</span>
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    value={entry.description}
                    onChange={handleInputChange}
                    rows={3}
                    className="textarea textarea-bordered h-24"
                  ></textarea>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="form-control">
                    <label htmlFor="timeSpent" className="label">
                      <span className="label-text">Time Spent (minutes)*</span>
                    </label>
                    <select
                      id="timeSpent"
                      name="timeSpent"
                      value={entry.timeSpent}
                      onChange={handleInputChange}
                      className="select select-bordered w-full"
                      required
                    >
                      <option value="">Select Time</option>
                      {timeOptions.map((time) => (
                        <option key={time} value={time.toString()}>
                          {time} mins
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-control">
                    <label htmlFor="level" className="label">
                      <span className="label-text">Level*</span>
                    </label>
                    <select
                      id="level"
                      name="level"
                      value={entry.level}
                      onChange={handleInputChange}
                      className="select select-bordered w-full"
                      required
                    >
                      <option value="">Select Level</option>
                      <option value="Easy">Easy</option>
                      <option value="Medium">Medium</option>
                      <option value="Hard">Hard</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/* Problem Details Card */}
            <div className="card bg-base-200 shadow-xl">
              <div className="card-body">
                <h2 className="card-title">Problem Details</h2>
                <div className="form-control">
                  <label htmlFor="tags" className="label">
                    <span className="label-text">Tags</span>
                  </label>
                  <div className="flex flex-wrap gap-2 mb-2">
                    {entry.tag.map((tag) => (
                      <span key={tag} className="badge badge-primary badge-lg">
                        {tag}
                        <button
                          type="button"
                          onClick={() => removeTag(tag)}
                          className="ml-1 text-xs"
                        >
                          ×
                        </button>
                      </span>
                    ))}
                  </div>
                  <div className="flex flex-wrap gap-2 mt-2">
                    {tagOptions.map((tag) => (
                      <button
                        key={tag}
                        type="button"
                        onClick={() => addTag(tag)}
                        className={`btn btn-sm ${
                          entry.tag.includes(tag)
                            ? "btn-primary"
                            : "btn-outline"
                        }`}
                      >
                        {tag}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="form-control">
                  <label htmlFor="hints" className="label">
                    <span className="label-text">Hints</span>
                  </label>
                  <textarea
                    id="hints"
                    name="hints"
                    value={entry.hints}
                    onChange={handleInputChange}
                    rows={2}
                    className="textarea textarea-bordered h-20"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          {/* Right side: Code Section */}
          <div className="lg:w-1/2 card bg-base-200 shadow-xl">
            <div className="card-body">
              <h2 className="card-title">Solution</h2>

              <div className="form-control">
                <label htmlFor="codeSnippet" className="label">
                  <span className="label-text">Code Snippet</span>
                </label>
                <textarea
                  id="codeSnippet"
                  name="codeSnippet"
                  value={entry.codeSnippet}
                  onChange={(e) => {
                    handleInputChange(e);
                    handleCodeResize(e);
                  }}
                  className="textarea textarea-bordered font-mono resize-y min-h-[300px]"
                  style={{ height: codeHeight }}
                ></textarea>
              </div>
              <div className="form-control mt-4">
                <label
                  htmlFor="solved"
                  className="label cursor-pointer justify-start"
                >
                  <input
                    type="checkbox"
                    id="solved"
                    name="solved"
                    checked={entry.solved}
                    onChange={handleInputChange}
                    className="checkbox checkbox-primary"
                  />
                  <span className="label-text ml-2">Solved</span>
                </label>
              </div>
            </div>
          </div>
        </form>

        <div className="flex justify-end space-x-2 mt-6">
          <button
            type="button"
            onClick={() => navigate("/algorithms")}
            className="btn btn-outline"
          >
            Cancel
          </button>
          <button
            type="submit"
            form="algorithm-form"
            className="btn btn-primary"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlgorithmEntryForm;
