import React, { useState, useEffect } from "react";
import DynamicTable from "./DynamicTable";
import { useAlgorithm } from "../pages/AlgorithmContext";
import { useMessage } from "../pages/MessageContext";
import { Algorithm } from "../types/Algorithm"; // Make sure to create this type if you haven't already
import { getAuth } from "firebase/auth";
import { logButtonClick } from "../analytics";

// import AlgorithmDetailModal from "./AlgorithmDetailModel";

const MasterAlgorithmList: React.FC = () => {
  const [masterAlgorithms, setMasterAlgorithms] = useState<Algorithm[]>([]);
  const [loading, setLoading] = useState(true);
  const { addAlgorithmFromMaster } = useAlgorithm();
  const { setMessage } = useMessage();

  useEffect(() => {
    let API_URL: string;
    if (process.env.NODE_ENV === "production") {
      API_URL = process.env.REACT_APP_API_URL_MASTER_PRODUCTION || "";
    } else {
      console.log("dev env..");
      API_URL = process.env.REACT_APP_API_URL_MASTER_DEVELOPMENT || "";
    }

    // Helper function to create headers with bearer token
    const createHeaders = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        throw new Error("User not authenticated");
      }

      const token = await user.getIdToken();

      const headers = new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });
      return headers;
    };

    if (!API_URL) {
      throw new Error("API_URL is not defined in environment variables");
    }

    const fetchMasterAlgorithms = async () => {
      try {
        const headers = await createHeaders();
        const response = await fetch(API_URL, { headers });
        if (!response.ok) {
          throw new Error("Failed to fetch master algorithms");
        }
        const data = await response.json();
        setMasterAlgorithms(data);
      } catch (error) {
        console.error("Error fetching master algorithms:", error);
        // setMessage("Failed to load master algorithms. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchMasterAlgorithms();
  }, [setMessage]);

  const handleAddToMyList = async (algorithm: Algorithm) => {
    try {
      logButtonClick("add_from_blind_question");
      await addAlgorithmFromMaster(algorithm.id);
      setMessage({
        text: "Algorithm added to your list successfully!",
        type: "success",
      });
    } catch (error) {
      console.error("Error adding algorithm to list:", error);
      setMessage({
        text: "Failed to add algorithm to your list. Please try again.",
        type: "error",
      });
    }
  };

  // const handleRowClick = async (algorithm: Algorithm) => {
  //   try {
  //     const response = await fetch(`API_URL/${algorithm.id}`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
  //       },
  //     });
  //     if (!response.ok) {
  //       throw new Error("Failed to fetch algorithm details");
  //     }
  //     const detailedAlgorithm = await response.json();
  //     setSelectedAlgorithm(detailedAlgorithm);
  //     setShowDetailModal(true);
  //   } catch (error) {
  //     console.error("Error fetching algorithm details:", error);
  //     setMessage("Failed to load algorithm details. Please try again.");
  //   }
  // };

  const columns = [
    { key: "title", label: "Title" },
    { key: "tags", label: "Tags" },
    { key: "level", label: "Level" },
    { key: "actions", label: "Actions" },
  ];

  if (loading) {
    return <div>Loading blind 75 algorithms...</div>;
  }

  return (
    <div>
      <DynamicTable
        data={masterAlgorithms}
        columns={columns}
        startIndex={0}
        selectedRows={[]}
        setSelectedRows={() => {}}
        onSort={() => {}}
        sortColumn={null}
        sortDirection={null}
        onSetReminder={() => {}}
        onDelete={() => {}}
        isMasterList={true}
        onAddToMyList={handleAddToMyList}
      />
    </div>
  );
};

export default MasterAlgorithmList;
