import React, { createContext, useState, useContext, ReactNode } from "react";

interface MessageContextType {
  message: { text: string | null; type: "success" | "error" | "info" } | null;
  setMessage: (
    message: { text: string | null; type: "success" | "error" | "info" } | null,
  ) => void;
}

const MessageContext = createContext<MessageContextType | undefined>(undefined);

export const MessageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [message, setMessage] = useState<{
    text: string | null;
    type: "success" | "error" | "info";
  } | null>(null);

  return (
    <MessageContext.Provider value={{ message, setMessage }}>
      {children}
    </MessageContext.Provider>
  );
};

export const useMessage = () => {
  const context = useContext(MessageContext);
  if (context === undefined) {
    throw new Error("useMessage must be used within a MessageProvider");
  }
  return context;
};
