import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { systemDesignApi } from "../api/systemDesignApi";
import { SystemDesign } from "../types/SystemDesign";

const SystemDesignDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [design, setDesign] = useState<SystemDesign | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedDesign, setEditedDesign] = useState<SystemDesign | null>(null);
  const [difficulty, setDifficulty] = useState<"Easy" | "Medium" | "Hard">(
    "Medium",
  );
  useEffect(() => {
    fetchDesign();
  }, [id]);

  const fetchDesign = async () => {
    try {
      const fetchedDesign = await systemDesignApi.getById(Number(id));
      setDesign(fetchedDesign);
      setEditedDesign(fetchedDesign);
    } catch (error) {
      console.error("Error fetching system design:", error);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setEditedDesign((prev) => (prev ? { ...prev, [name]: value } : null));
  };

  const handleSave = async () => {
    if (editedDesign) {
      try {
        await systemDesignApi.update(editedDesign.id, editedDesign);
        setDesign(editedDesign);
        setIsEditing(false);
      } catch (error) {
        console.error("Error updating system design:", error);
      }
    }
  };

  if (!design) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>{`${design.title} - System Design - AlgoBuddy`}</title>
        <meta
          name="description"
          content={`Learn about ${design.title} system design. Company: ${design.company}. ${design.description}`}
        />
      </Helmet>

      <div className="container mx-auto p-4 max-w-4xl pt-16">
        <button
          onClick={() => navigate("/system-design")}
          className="btn btn-circle btn-outline absolute top-4 left-4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>

        {isEditing ? (
          <div className="card bg-base-200 shadow-xl mb-6">
            <div className="card-body">
              <h2 className="card-title">Edit System Design</h2>
              <input
                type="text"
                name="title"
                value={editedDesign?.title || ""}
                onChange={handleInputChange}
                className="input input-bordered w-full mb-2"
              />
              <input
                type="text"
                name="company"
                value={editedDesign?.company || ""}
                onChange={handleInputChange}
                className="input input-bordered w-full mb-2"
              />
              <textarea
                name="description"
                value={editedDesign?.description || ""}
                onChange={handleInputChange}
                className="textarea textarea-bordered w-full mb-2"
              />
              <select
                name="difficulty"
                value={editedDesign?.difficulty || ""}
                onChange={(e) =>
                  setDifficulty(e.target.value as "Easy" | "Medium" | "Hard")
                }
                className="select select-bordered w-full mb-2"
              >
                <option value="Easy">Easy</option>
                <option value="Medium">Medium</option>
                <option value="Hard">Hard</option>
              </select>
              {/* <textarea
                name="details"
                value={editedDesign?.description || ""}
                onChange={handleInputChange}
                className="textarea textarea-bordered w-full mb-2"
              /> */}
              <div className="card-actions justify-end">
                <button
                  onClick={() => setIsEditing(false)}
                  className="btn btn-ghost"
                >
                  Cancel
                </button>
                <button onClick={handleSave} className="btn btn-primary">
                  Save
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <h1 className="text-3xl font-bold mb-6 text-center">
              {design.title}
            </h1>
            {/* ... (rest of your existing JSX for displaying design details) ... */}
            <div className="card-actions justify-end mt-4">
              <button
                onClick={() => setIsEditing(true)}
                className="btn btn-primary"
              >
                Edit
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SystemDesignDetailPage;
