import React from "react";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { LogOut } from "lucide-react";

interface UserProfileProps {
  isCollapsed: boolean;
}

const UserProfile: React.FC<UserProfileProps> = ({ isCollapsed }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem("bearerToken");
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  if (!user) return null;

  return (
    <div
      className={`absolute bottom-4 left-0 bg-base-200 rounded-r-lg shadow-lg transition-all duration-300 ${
        isCollapsed ? "w-16" : "w-64"
      }`}
    >
      <div className="p-4">
        {isCollapsed ? (
          <div className="flex flex-col items-center">
            <div className="avatar mb-2">
              <div className="w-10 rounded-full">
                <img
                  src={user.photoURL || "https://via.placeholder.com/40"}
                  alt="User avatar"
                />
              </div>
            </div>
            <button
              onClick={handleSignOut}
              className="btn btn-ghost btn-circle btn-sm"
              title="Sign Out"
            >
              <LogOut size={18} />
            </button>
          </div>
        ) : (
          <>
            <div className="flex items-center mb-2">
              <div className="avatar">
                <div className="w-10 rounded-full">
                  <img
                    src={user.photoURL || "https://via.placeholder.com/40"}
                    alt="User avatar"
                  />
                </div>
              </div>
              <div className="ml-3 overflow-hidden">
                <p className="font-semibold truncate">
                  {user.displayName || "User"}
                </p>
                <p className="text-sm text-base-content/70 truncate">
                  {user.email}
                </p>
              </div>
            </div>
            <button
              onClick={handleSignOut}
              className="btn btn-sm btn-outline btn-error w-full"
            >
              Sign Out
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
