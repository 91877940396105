import React from "react";
import { MessageSquare } from "lucide-react";

const FeedbackButton: React.FC = () => {
  const feedbackFormUrl = "https://forms.gle/fMJKSdprZEHAQv148";

  const openFeedbackForm = () => {
    window.open(feedbackFormUrl, "_blank");
  };

  return (
    <button
      onClick={openFeedbackForm}
      className="fixed bottom-4 right-4 btn btn-primary btn-circle"
      title="Provide Feedback"
    >
      <MessageSquare className="h-6 w-6" />
    </button>
  );
};

export default FeedbackButton;
