import axios from "axios";
import { SystemDesign } from "../types/SystemDesign";
import { getAuth } from "firebase/auth";

let API_BASE_URL = "/api/system-design";

// Helper function to create headers with bearer token
const createHeaders = async () => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error("User not authenticated");
  }

  const token = await user.getIdToken();

  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

if (process.env.NODE_ENV === "production") {
  API_BASE_URL = process.env.REACT_APP_SD_API_URL_PRODUCTION || "";
} else {
  console.log("dev env..");
  API_BASE_URL = process.env.REACT_APP_SD_API_URL_DEVELOPMENT || "";
}
console.log(process.env.NODE_ENV);

console.log(API_BASE_URL);

// API_BASE_URL = process.env.REACT_APP_SD_API_URL_DEVELOPMENT || "";

export const systemDesignApi = {
  getAll: async (): Promise<SystemDesign[]> => {
    const headers = await createHeaders();
    const response = await axios.get(API_BASE_URL, { headers });
    return response.data;
  },

  create: async (
    newDesign: Omit<SystemDesign, "id">,
  ): Promise<SystemDesign> => {
    const headers = await createHeaders();
    const response = await axios.post(API_BASE_URL, newDesign, { headers });
    return response.data;
  },

  update: async (
    id: number,
    updatedDesign: SystemDesign,
  ): Promise<SystemDesign> => {
    const headers = await createHeaders();
    const response = await axios.put(`${API_BASE_URL}/${id}`, updatedDesign, {
      headers,
    });
    return response.data;
  },

  delete: async (id: number): Promise<void> => {
    const headers = await createHeaders();
    await axios.delete(`${API_BASE_URL}/${id}`, { headers });
  },

  getById: async (id: number): Promise<SystemDesign> => {
    const headers = await createHeaders();
    const response = await axios.get(`${API_BASE_URL}/${id}`, { headers });
    return response.data;
  },
};
