import React, { useState, useEffect } from "react";

interface ReminderModalProps {
  algorithm: any;
  onClose: () => void;
  onSetReminder: (id: number, date: Date | null) => void;
}

const ReminderModal: React.FC<ReminderModalProps> = ({
  algorithm,
  onClose,
  onSetReminder,
}) => {
  const [reminderDate, setReminderDate] = useState<string>("");

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setReminderDate(
      algorithm.reminder
        ? new Date(algorithm.reminder).toISOString().split("T")[0]
        : today,
    );
  }, [algorithm]);

  const handleSetReminder = () => {
    if (reminderDate) {
      const date = new Date(reminderDate + "T00:00:00Z");
      onSetReminder(algorithm.id, date);
    } else {
      onSetReminder(algorithm.id, null);
    }
    onClose();
  };

  const today = new Date().toISOString().split("T")[0];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-base-100 p-6 rounded-lg shadow-xl">
        <h2 className="text-xl font-bold mb-4">
          Set Reminder for {algorithm.title}
        </h2>
        <input
          type="date"
          value={reminderDate}
          min={today}
          onChange={(e) => setReminderDate(e.target.value)}
          className="input input-bordered w-full mb-4"
        />
        <div className="flex justify-end space-x-2">
          <button onClick={onClose} className="btn btn-ghost">
            Cancel
          </button>
          <button onClick={handleSetReminder} className="btn btn-primary">
            Set Reminder
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReminderModal;
